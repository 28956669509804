<template>
  <b-modal id="bv-modal-example" v-model="modalChips" size="lg" hide-footer>
    <template #modal-title>{{
      isAddingChip ? $t('helpers.add') + "chips" : $t('configChips.title')
      }}</template>
    <div class="row mb-4" v-if="!chipSelected">
      <div>
        <div class="d-flex mb-3 justify-content-between" v-if="!isAddingChip">
          <h5>{{ $t('configChips.saved') }}</h5>
          <div class="col-4">
            <div class="d-flex align-items-center" v-if="rouletteCurrency">
              <label class="me-2">{{ $t('filter.filter') }}: </label>
              <multiselect v-model="filterCurrency.currency" :options="formatOptions(rouletteCurrency.currencies)"
                @input="handleFilter" label="name" placeholder="Selecciona una moneda"
                select-label="Click para filtrar">
              </multiselect>
            </div>
          </div>
        </div>
        <div v-if="!chips.length">{{ $t('configChips.empty') }}</div>
        <div class="row" v-if="chips.length">
          <div v-for="(chip, index) in chips" :key="index" class="col-sm-12 col-md-5 card mx-auto"
            :style="chip.active ? 'opacity: 1' : 'opacity: 0.8'">
            <span class="badge bg-secondary badge-status rounded-pill">{{
              chip.active ? $t('configChips.ok') : $t('configChips.noOk')
              }}</span>

            <div class="d-flex align-items-center" @click="() => selectChip(chip)">
              <Chip :chip="chip" />
              <div class="chip-info">
                <span><strong>{{ $t('configChips.number') }}:</strong> {{ chip.number }}</span>
                <span><strong>{{ $t('configChips.value') }}:</strong> {{ chip.value }}
                  {{ chip.currency.short }}</span>
                <span><strong>{{ $t('configChips.firstColor') }}:</strong>
                  {{ chip.color.primary }}</span>
                <span><strong>{{ $t('configChips.secondColor') }}:</strong>
                  {{ chip.color.secondary }}</span>
              </div>
            </div>
            <span class="badge bg-primary rounded-pill">{{ $t('configChips.edit') }}</span>
            <span class="badge delete-chip bg-danger rounded-pill" @click="deleteChip(chip)" v-if="!isAddingChip">
              <span class="uil-trash-alt me-1"></span>
              {{ $t('actions.subItems.delete') }}
            </span>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center" v-if="!isAddingChip">
          <p class="btn btn-primary btn-plus" @click="addAnotherChip">
            +
            <span class="badge bg-secondary rounded-pill">{{ $t('configChips.addMore') }}</span>
          </p>
          <div class="pagination">
            <b-button class="uil-arrow-left" variant="primary" @click="handlePrev"
              :disabled="chipPage === 1"></b-button>
            <b-button class="uil-arrow-right" variant="primary" @click="handleNext"
              :disabled="chips.length < 6"></b-button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="chipSelected">
      <p>
        <span class="btn btn-secondary me-3" @click="handleBack">&larrhk;</span>Regresar
      </p>
      <div class="d-flex justify-content-end ms-auto w-25 flex-column" v-if="rouletteCurrency">
        <label>{{ $t('configChips.selectCurrency') }}: </label>
        <multiselect v-model="typeform.currency" :options="formatOptions(rouletteCurrency.currencies)" label="name"
          :placeholder="$t('configChips.selectCurrency')"></multiselect>
        <div v-if="$v.typeform.currency.$error" class="invalid-feedback">
          <span v-if="!$v.typeform.currency.required">Este campo es requerido</span>
        </div>
      </div>

      <div class="d-flex justify-content-center" v-if="Object.entries(typeform).length">
        <Chip :chip="typeform" />
      </div>
      <form>
        <b-row class="mb-3 mx-auto">
          <b-col sm="12" md="6" class="mx-auto">
            <div class="mb-3">
              <label>{{ $t('configChips.number') }}</label>
              <input v-model="typeform.number" type="text" :class="`form-control ${$v.typeform.number.$error ? 'is-invalid' : ''
    }`" placeholder="1000" name="providerId" maxlength="7" />
              <div v-if="$v.typeform.number.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.number.maxLength">
                  7 caracteres maximos</span>
                <span v-if="!$v.typeform.number.numeric" class="d-block">
                  Solo valores numericos</span>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6" class="mx-auto">
            <div class="mb-3">
              <label>{{ $t('configChips.value') }} ({{
                typeform.currency ? typeform.currency.name : "MONEDA"
                }})</label>
              <input v-model="typeform.value" type="text" class="form-control" placeholder="1.5" name="providerId"
                maxlength="7" :class="`form-control ${$v.typeform.value.$error ? 'is-invalid' : ''
    }`" />
              <div v-if="$v.typeform.value.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.value.maxLength">
                  7 caracteres maximos</span>
                <span v-if="!$v.typeform.value.numeric" class="d-block">
                  Solo valores numericos</span>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-3 mx-auto">
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>{{ $t('configChips.firstColor') }}</label>
              <input v-model="typeform.color.primary" type="color" class="form-control" placeholder="HEX"
                name="providerId" />
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>{{ $t('configChips.secondColor') }}</label>
              <input v-model="typeform.color.secondary" type="color" class="form-control" placeholder="HEX"
                name="providerId" />
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>{{ $t('configChips.active') }}</label>
              <input id="chipIsActive" type="checkbox" :checked="typeform.active" class="form-check-input ms-2"
                v-model="typeform.active" />
            </div>
          </b-col>
        </b-row>

        <div class="mb-3 mx-auto">
          <div class="d-flex justify-content-center">
            <vs-button @click.prevent="save()" v-if="isAddingChip">
              {{ $t('helpers.add') }}
            </vs-button>
            <vs-button @click.prevent="save()" v-if="!isAddingChip">
              {{ $t('form.save') }}
            </vs-button>
            <vs-button @click="closeModa()" type="button" success>
              {{ $t('form.abort') }}
            </vs-button>
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import { maxLength, decimal, required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import { Chip } from "../../../../components";

export default {
  props: [
    "rouletteCurrency",
    "chips",
    "isAddingChip",
    "chipPage",
    "filterCurrency",
  ],
  components: { Multiselect, Chip },
  data() {
    return {
      chipSelected: false,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      modalChips: false,
      typeform: {
        currency: null,
      },
      selectionOpen: false,
      chipSelectedDetail: {},
    };
  },
  validations: {
    typeform: {
      number: {
        decimal,
        maxLength: maxLength(7),
      },
      currency: {
        required,
      },
      value: {
        decimal,
        maxLength: maxLength(7),
      },
    },
  },
  methods: {
    handleNext() {
      this.$emit("nextPageChip");
    },
    handlePrev() {
      this.$emit("prevPageChip");
    },
    handleFilter() {
      this.$emit("handleSelection");
    },
    formatOptions(currencies) {
      const shorts = currencies.map((curr) => {
        return { name: curr.short, _id: curr._id };
      });
      return shorts;
    },
    async deleteChip(chip) {
      const { isConfirmed } = await Swal.fire({
        title: "¿Está seguro en eliminar esta ficha?",
        showCancelButton: true,
        cancelButtonText: "NO",
        confirmButtonText: "SI",
        icon: "warning",
      });
      if (!isConfirmed) return;

      const { data } = await this.$http.delete(
        `roulettes/${chip._id}/delete-chip`
      );

      if (!data.ok) {
        return Swal.fire({
          title: "Error eliminando ficha",
          timer: 1000,
        });
      }

      this.closeModa();
      return Swal.fire({
        title: "Ficha eliminada",
        timer: 1000,
        showCancelButton: false,
      });
    },
    nFormatter(num, digits) {
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "K" },
        { value: 1e6, symbol: "M" },
      ];

      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

      const item = lookup
        .slice()
        .reverse()
        .find(function (item) {
          return num >= item.value;
        });

      return item
        ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
        : String(num).replace(rx, "$1");
    },
    addAnotherChip() {
      this.$emit("addAnotherChip");
    },
    handleBack() {
      this.chipSelected = false;
      this.chipSelectedDetail = {};
      this.typeform = {};
      this.$emit("handleGoBack");
    },
    save() {
      this.typesubmit = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return console.log("ERROR", this.$v);
      }

      if (this.isAddingChip) {
        this.$http
          .post("/roulettes/assign-chips", {
            rouletteId: this.rouletteCurrency.roulette._id,
            chipToSave: {
              ...this.typeform,
              currency: this.typeform.currency._id,
            },
          })
          .then(({ data }) => {
            if (!data.ok) {
              return Swal.fire({
                position: "center",
                icon: "error",
                title: data.msg,
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.closeModa();
              });
            }

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Chip almacenada",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              this.closeModa();
            });
          })
          .catch((err) => {
            console.log("Error guardando chip", err);
            return Swal.fire({
              position: "center",
              icon: "error",
              title: "Error guardando la chip",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      } else {
        this.$http
          .put(`roulettes/${this.rouletteCurrency.roulette._id}/update-chips`, {
            chipId: this.typeform._id,
            newChip: {
              ...this.typeform,
            },
          })
          .then(({ data }) => {
            if (data.ok) {
              return Swal.fire({
                position: "center",
                icon: "success",
                title: "Chip actualizada",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.closeModa();
              });
            }
          })
          .catch((err) => {
            console.log("Error guardando chip", err);
            return Swal.fire({
              position: "center",
              icon: "error",
              title: "Error guardando la chip",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    },
    closeModa() {
      this.typeform = {};
      this.chipSelected = false;
      this.modalChips = false;
      this.chipSelectedDetail = {};
      this.$emit("closeModa");
    },

    selectChip(chip) {
      this.chipSelectedDetail = chip;
      this.typeform = {
        number: chip.number,
        value: chip.value,
        color: chip.color,
        active: chip.active,
        ...chip,
      };
      this.chipSelected = true;
    },
  },
};
</script>

<style scoped>
.pagination>* {
  width: 30px;
  height: 30px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10%;
  margin-left: 5px;
  cursor: pointer;
  color: #fff;
}

.delete-chip {
  bottom: 0;
}

.badge {
  position: absolute;
  right: -10px;
  display: none;
}

.badge-status {
  display: block;
  left: 0;
  top: 0;
  right: inherit;
}

.card:hover {
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.274);
  cursor: pointer;
}

.card:hover .badge {
  display: block;
}

.btn-plus {
  position: relative;
}

.btn-plus .badge {
  right: inherit;
}

.btn-plus:hover .badge {
  display: block;
}

.chip-info {
  display: flex;
  flex-direction: column;
}
</style>
