<template>
  <b-modal id="bv-modal-animals" v-model="modal" size="lg" hide-footer>
    <template #modal-title>
      <h5>Configurar imagenes en el tablero (Plenos)</h5>
    </template>
    <form @submit.prevent="save">
      <b-row>
        <b-col sm="12" md="3" v-for="(item, i) in formattedAnimals" :key="i">
          <div class="mb-3">
            <template v-if="item.number === 37">
              <label>Number: 00 (Opcional)</label>
              <input @change="e => handleChange(e, { ...item, number: 37 })" :value="item.image ? item.image : ''"
                placeholder="URL" class="shadow-lg form-control border-0" type="url" />
            </template>
            <template v-else>
              <label>Number: {{ item.number }}</label>
              <input @change="e => handleChange(e, item)" :value="item.image ? item.image : ''" placeholder="URL"
                class="shadow-lg form-control border-0" type="url" />
            </template>
          </div>
        </b-col>
      </b-row>
      <div class="mb-3 mt-3">
        <div class="d-flex">
          <vs-button>{{ $t('form.save') }}</vs-button>
          <vs-button success type="button" @click="closeModal">{{ $t('form.abort') }}</vs-button>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import Swal from 'sweetalert2';

export default {
  props:
    ["roulette", "formattedAnimals"]
  ,
  data() {
    return {
      modal: false,
      typeform: {},
      formatted: [],
      animals: {}
    }
  },
  methods: {
    async save() {
      const animals = [];
      for (let key in this.typeform) {

        if (!this.typeform[key].image) continue

        const bet = {
          number: this.typeform[key].number,
          image: this.typeform[key].image
        }
        animals.push(bet);
      }

      const dataToUpdate = { ...this.roulette, animals };

      try {
        const { data } = await this.$http.put(`/roulettes/${this.roulette._id}`, dataToUpdate);

        if (!data.ok) {
          this.closeModal()
          return Swal.fire("Error salvando la ruleta", "", "info");
        }

        Swal.fire({
          title: "Ruleta actualizada",
          icon: "success"
        })
        this.closeModal()

      } catch (error) {
        console.log('ERROR SAVING ANIMALS', error);
      }
    },
    closeModal() {
      this.modal = false;
      this.typeform = {};
      this.$emit("closeModa")
    },
    handleChange(e, item) {

      this.typeform = {
        ...this.animals,
        ...this.typeform,
        [item.number]: {
          number: item.number,
          image: e.target.value
        }
      }

    }
  },
  watch: {
    formattedAnimals(newValue) {
      for (let value in newValue) {
        const animal = newValue[value];

        if (animal.number === 37) {
          this.animals[37] = {
            ...animal,
            number: 37
          }
          continue
        }

        this.animals[animal.number] = {
          ...animal
        }
      }

    }
  }
}
</script>
