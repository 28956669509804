<template>
  <Layout>
    <PageHeader
      :title="title"
      moda_title="Datos de la ruleta"
      ref="pageForm"
      :items="items"
      :isAdmin="isItForAdmin()">
      <template>
        <PageForm
          :typeform="typeform"
          :currencies="currencies"
          :rouletteFisics="rouletteFisics"
          @closeModa="closeModa"
          @getData="getData" />
      </template>
    </PageHeader>
    <add-currency
      :currencies="currencies"
      :roulette="roulette"
      ref="addCurrency" />
    <config-chips
      ref="configChips"
      :rouletteCurrency="rouletteCurrency"
      :isAddingChip="isAddingChip"
      :chips="chips"
      @closeModa="closemodal"
      @addAnotherChip="addAnotherChip"
      @handleGoBack="handleGoBack"
      @handleSelection="handleSelection"
      @nextPageChip="nextPageChip"
      @prevPageChip="prevPageChip"
      :chipPage="chipPage"
      :filterCurrency="filterCurrency"></config-chips>
    <ImportCurrencies
      ref="ImportCurrencies"
      :roulettes="roulettes"
      :roulette="roulette" />
    <config-limits
      ref="configLimitRoulette"
      :currencies="rouletteCurrencies"
      :roulette="rouletteId"
      :limitsByCurrencies="limitsByCurrencies"
      :isUpdating="updatingLimits"
      :currWithOutLimits="currWithOutLimits"
      :noLimitsCurrencies="noLimitsCurrencies"></config-limits>
    <game-menu
      ref="gameMenu"
      :items="gameMenuItems"
      @handleDirection="handleDirection"></game-menu>
    <ConfigAnimals
      ref="configAnimals"
      :roulette="roulette"
      :formattedAnimals="formattedAnimals"
      @closeModa="closeModa">
    </ConfigAnimals>
    <JackpotBank
      ref="jackpotBankComponent"
      :game="roulette"
      @reload="reloadJackpot" />
    <JackpotsModules
      ref="jackpotsModulesComponent"
      :game="roulette"
      :jackpotsModules="jackpotModules"
      :profit="profit"
      @reload="reloadProfit" />
    <OpeningClosing
      ref="openingClosingComponent"
      :game="roulette"
      :type="gamesTypes.Roulette"
      @closeModal="closeModa" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row d-flex">
              <div class="col-6">
                <Tooltip>
                  <span>Pasos:</span>
                  <ol>
                    <li>
                      Agregar:
                      <ol>
                        <li>Ruleta fisica</li>
                        <li>Valores de las apuestas</li>
                        <li>
                          En este apartado puedes asignar valores min. and max.
                          para las apuestas de forma generica.
                        </li>
                      </ol>
                    </li>
                    <li>Agregar las monedas para la ruleta.</li>
                    <li>
                      Agregar las fichas para la ruleta. Cada moneda debe tener
                      sus fichas.
                    </li>
                    <li>
                      Configurar limites: en este apartado podrás asignar los
                      limites personalizados por las monedas disponibles.
                    </li>
                    <li>
                      Activar / Desactivar: esta opción habilita, o no, que la
                      ruleta aparezca en las salas.
                      <span
                        >Nota: esta acción se realiza para todos los clientes /
                        operadores que posean esta ruleta.</span
                      >
                    </li>
                  </ol>
                </Tooltip>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <change-view
                  :destiny="$t('routeMenu.rouletteFisic')"
                  route="/roulettes-fisics"></change-view>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t("filter.show") }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option
                        v-for="(op, index) in pageOptions"
                        :key="index"
                        :label="op"
                        :value="op"
                        >{{ op }}</vs-option
                      >
                    </vs-select>
                    &nbsp;{{ $t("filter.entries") }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    {{ $t("filter.search") }}:
                    <vs-input
                      v-model="filter"
                      type="search"
                      placeholder="Buscar..."
                      primary
                      class="shadow-lg"></vs-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered">
                <template #cell(logo)="data">
                  <b-img
                    :src="data.item.logo"
                    height="80px"
                    class="logo-img"
                    @error="handleError"></b-img>
                </template>
                <template #cell(active)="data">
                  <vs-tooltip left>
                    <vs-button
                      :disabled="activeDisable(data.item.active)"
                      :color="colorActive(data.item.active)">
                      <!-- @click="hanbleActive(data.item)" -->
                      {{ data.item.active ? "SI" : "NO" }}
                    </vs-button>
                    <template #tooltip>
                      {{ tooltipActive(data.item) }}
                    </template>
                  </vs-tooltip>
                </template>
                <template #cell(manualDisable)="data">
                  <vs-tooltip left>
                    <vs-button
                      :disabled="activeDisable(!data.item.manualDisable)"
                      :color="colorActive(!data.item.manualDisable)">
                      <!-- @click="hanbleActive(data.item)" -->
                      {{ !data.item.manualDisable ? "ACTIVA" : "INACTIVA" }}
                    </vs-button>
                    <template #tooltip>
                      {{ tooltipManualDisable(data.item.manualDisable) }}
                    </template>
                  </vs-tooltip>
                </template>
                <!-- <template #cell(openingTime)="data">
                  {{ formatTimefromUtcToLocal(data.item.openingTime) }}
                </template>
                <template #cell(closingTime)="data">
                  {{ formatTimefromUtcToLocal(data.item.closingTime) }}

                </template> -->
                <!-- Column: Actions -->

                <template #cell(actions)="data">
                  <div class="action-btn-container">
                    <b-dropdown
                      variant="link"
                      no-caret
                      class="position-absolute"
                      menu-class="position-absolute start-3 top-2">
                      <template #button-content>
                        <i class="fa fa-fw fa-bars font-size-16" />
                      </template>
                      <b-dropdown-item
                        :to="{
                          name: 'roulettes-show',
                          params: { id: data.item._id },
                        }">
                        <i class="uil-file-alt m-2 font-size-18" />
                        <span class="align-middle ml-5">{{
                          $t("actions.subItems.details").toUpperCase()
                        }}</span>
                      </b-dropdown-item>
                      <div v-if="isItForAdmin()">
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="uil-edit m-2 font-size-18" />
                          <span class="align-middle ml-5">{{
                            $t("actions.subItems.edit").toUpperCase()
                          }}</span>
                        </b-dropdown-item>
                      </div>
                      <!-- <div v-if="isItForAdmin()">
                        <b-dropdown-item @click="addCurrency(data.item)">
                          <i class="uil-bullseye m-2 font-size-18" />
                          <span class="align-middle ml-5">{{
                            $t("actions.subItems.addCurrency")
                          }}</span>
                        </b-dropdown-item>
                      </div> -->
                      <!-- <div v-if="isItForAdmin()">
                        <b-dropdown-item @click="configChips(data.item)">
                          <i class="uil-bullseye m-2 font-size-18" />
                          <span class="align-middle ml-5">{{
                            $t("actions.subItems.configChips")
                          }}</span>
                        </b-dropdown-item>
                      </div> -->
                      <div v-if="isItForAdmin()">
                        <b-dropdown-item @click="configAnimals(data.item)">
                          <i class="uil-image m-2 font-size-18" />
                          <span class="align-middle ml-5">{{
                            "Configurar animales".toUpperCase()
                          }}</span>
                        </b-dropdown-item>
                      </div>
                      <!-- <div v-if="isItForAdmin()">
                        <b-dropdown-item @click="configLimits(data.item)">
                          <i class="uil-file-alt m-2 font-size-18" />
                          <span class="align-middle ml-5">{{
                            $t("actions.subItems.configLimits")
                          }}</span>
                        </b-dropdown-item>
                      </div> -->

                      <div v-if="isItForAdmin()">
                        <b-dropdown-item @click="changeActivate(data.item)">
                          <i class="mdi mdi-sync m-2 font-size-18" />
                          <span class="align-middle ml-5">
                            {{
                              data.item.manualDisable ? "ACTIVAR" : "DESACTIVAR"
                            }}
                            MANUAL
                          </span>
                        </b-dropdown-item>
                      </div>
                      <div>
                        <b-dropdown-item @click="goManualResult(data.item)">
                          <i class="uil-edit m-2 font-size-18" />
                          <a href="/"
                            ><span class="align-middle ml-5">{{
                              "Agg resultado manual".toUpperCase()
                            }}</span></a
                          >
                        </b-dropdown-item>
                      </div>
                      <div>
                        <b-dropdown-item @click="goJackpotBank(data.item)">
                          <i class="uil-money-stack m-2 font-size-18" />
                          <span class="align-middle ml-5">{{
                            "Ver banco de jackpot".toUpperCase()
                          }}</span>
                        </b-dropdown-item>
                      </div>
                      <div>
                        <b-dropdown-item @click="goProfitRoulette(data.item)">
                          <i class="uil-money-stack m-2 font-size-18" />
                          <span class="align-middle ml-5">{{
                            "modulos de jackpots".toUpperCase()
                          }}</span>
                        </b-dropdown-item>
                      </div>
                      <div>
                        <b-dropdown-item @click="setOpeningClosing(data.item)">
                          <i class="uil-calendar-alt m-2 font-size-18" />
                          <span class="align-middle ml-5"
                            >APERTURA / CIERRE</span
                          >
                        </b-dropdown-item>
                      </div>
                      <div>
                        <b-dropdown-item @click="goTablero(data.item)">
                          <i
                            class="mdi mdi-google-controller m-2 font-size-18" />
                          <a href="/"
                            ><span class="align-middle ml-5">{{
                              "Jugar".toUpperCase()
                            }}</span></a
                          >
                        </b-dropdown-item>
                      </div>
                      <div v-if="isItForAdmin()">
                        <b-dropdown-item @click="remove(data.item._id)">
                          <i class="mdi mdi-delete-outline m-2 font-size-18" />
                          <span class="align-middle ml-5">{{
                            $t("actions.subItems.delete").toUpperCase()
                          }}</span>
                        </b-dropdown-item>
                      </div>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import PageForm from "./form.vue";
import ConfigAnimals from "./configAnimals.vue";
import Swal from "sweetalert2";
import { breadCrumbData } from "@/helpers/breadcrumbs/breadCrumbs";
import ImportCurrencies from "./ImportCurrencies.vue";
import AddCurrency from "./AddCurrency.vue";
import ConfigChips from "./ConfigChips.vue";
import ConfigLimits from "./ConfigLimits.vue";
import JackpotsModules from "./JackpotsModules.vue";
import { GameMenu, Tooltip, ChangeView } from "../../../../components";
import { filterMenu } from "@/helpers/filterMenu";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { formatAnimals } from "./utils/formatAnimals";
import { JackpotBank, OpeningClosing } from "../components";

/**
 * Users-table component
 */
export default {
  page: {
    title: "Roulettes Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    PageForm,
    ImportCurrencies,
    AddCurrency,
    ConfigChips,
    ConfigLimits,
    GameMenu,
    Tooltip,
    ChangeView,
    ConfigAnimals,
    JackpotBank,
    OpeningClosing,
    JackpotsModules,
  },
  data() {
    return {
      gameMenuItems: [],
      chips: [],
      tableData: [],
      currencies: [],
      title: this.$t("sidebar.games.subItem.roulettes"),
      items: breadCrumbData.roulettes,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      clients: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "name",
          sortable: true,
          label: this.$t("roulettes.name").toUpperCase(),
        },
        {
          key: "logo",
          sortable: false,
          label: this.$t("roulettes.logo").toUpperCase(),
          class: "text-center",
        },
        {
          key: "active",
          sortable: true,
          label: this.$t("roulettes.active").toUpperCase(),
          class: "d-flex justify-content-center",
        },
        {
          key: "manualDisable",
          sortable: false,
          label: this.$t("roulettes.manualDisable").toUpperCase(),
        },
        {
          key: "openingTime",
          sortable: false,
          label: "APERTURA",
        },
        {
          key: "closingTime",
          sortable: false,
          label: "CIERRE",
        },
        {
          key: "actions",
          sortable: false,
          label: this.$t("actions.text").toUpperCase(),
          class: "align-middle",
        },
      ],
      roulettes: [],
      typeform: {
        doubleZero: "",
        code: "",
        logo: "",
        roundDuration: "",
        lastJackpot: "",
        jackpotRounds: "",
        currenJackpotRound: "",
        jackpotWin: "",
        interface: "",
        minBet: null,
        maxBet: null,
      },
      chipsForm: {
        chip: "",
        value: "",
        color: "",
        isActive: true,
      },
      roulette: {},
      rouletteCurrency: null,
      rouletteCurrencies: [],
      rouletteId: "",
      isAddingChip: false,
      userRole: "",
      limitsByCurrencies: [],
      updatingLimits: false,
      rouletteFisics: [],
      currWithOutLimits: [],
      noLimitsCurrencies: [],
      filterChip: {},
      chipPage: 1,
      filterCurrency: {},
      formattedAnimals: [],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    colorActive: () => (bool) => {
      return bool ? "#2357a7" : "#d14949";
    },
    activeDisable: () => (bool) => {
      return bool ? false : true;
    },
    tooltipActive: () => (game) => {
      const { active, manualDisable } = game;
      if (!active && manualDisable)
        return "ACTIVE MANUALMENTE PARA RECIBIR RONDAS";
      if (active && manualDisable)
        return "ACTIVE MANUALMENTE PARA RECIBIR RONDAS";
      if (active && !manualDisable) return "RECIBIENDO RONDAS";
      if (!active && !manualDisable) return "ESPERANDO RECIBIR RONDAS";
    },
    tooltipManualDisable: () => (bool) => {
      return bool
        ? "AL LLEGAR UNA RONDA NO SE ACTIVARA LA RULETA"
        : "VISIBLE EN LOBBY";
    },
    ...mapGetters({
      getGameCurrencies: "games/getCurrencies",
      mapGames: "games/getGames",
      nFormatter: "chips/nFormatter",
      success: "games/isSuccess",
      formatTimefromUtcToLocal: "reports/formatTimefromUtcToLocal",
      gamesTypes: "reports/gamesTypes",
      profit: "games/getProfit",
      jackpotModules: "games/getModules",
    }),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getData();
    this.getUserRole();

    this.getCurrencies();
    this.getRouletteFisics();
    this.handleOpen();
  },
  methods: {
    async hanbleActive(game) {
      const { active } = game;

      const title = `ESTAS SEGURO DE DESACTIVAR?`;

      const text =
        "DESACTIVA LA RULETA. SE SEGUIRA MOSTRANDO EN LOBBY. SI LLEGA RONDA SE ACTIVARA. VERIFIQUE HORA DE APERTURA";

      const { isConfirmed } = await Swal.fire({
        position: "center",
        icon: "question",
        title,
        showConfirmButton: true,
        confirmButtonText: "Si",
        showDenyButton: true,
        text,
      });

      if (!isConfirmed) return;

      await this.update({ ...game, active: !active });

      if (!this.success)
        return Swal.fire("Error desactivando la ruleta", "", "error");

      Swal.fire({
        title: "RULETA DESACTIVADA",
        text: "VERIFIQUE HORA DE APERTURA",
        icon: "success",
        timer: 2000,
      });

      this.getData();
    },
    nextPageChip() {
      this.chipPage++;
      this.handleSelection();
    },
    prevPageChip() {
      this.chipPage--;
      this.handleSelection();
    },
    async getRouletteChips() {
      return await this.$http.get(`/roulettes/show-chips/${this.rouletteId}`, {
        params: {
          ...this.filterChip,
          page: this.chipPage,
        },
      });
    },
    async handleSelection() {
      const { currency } = this.filterCurrency;
      if (currency) {
        this.filterChip = { currencyId: currency._id };
      }

      const { data } = await this.getRouletteChips();
      this.isAddingChip = false;
      if (!data.ok) {
        this.chips = [];
      }

      if (data.ok) {
        this.chips = data.chips.map((chip) => {
          return { ...chip, currency: chip.rouletteCurrency[0] };
        });
      }
      return (this.$refs.configChips.modalChips = true);
    },
    isItForAdmin() {
      return ["ADMIN"].includes(this.userRole);
    },
    getUserRole() {
      this.userData = this.$store.getters["user/getUserData"];
      this.userRole = this.userData.role.name;
    },
    closemodal() {
      this.filterCurrency = {};
      this.$refs.modalChips = false;
      this.rouletteCurrency = null;
      this.rouletteId = "";
      this.chips = [];
    },
    async configChips(value) {
      this.filterChip = {};
      this.filterCurrency = {};
      this.chips = [];
      this.chipPage = 1;
      if (value) {
        this.rouletteId = value._id;
      }

      try {
        const { data } = await this.getRouletteChips();

        const { data: rouletteCurrencies } = await this.$http.get(
          `roulettes/${this.rouletteId}/currencies`
        );

        if (!rouletteCurrencies.currencies.length) {
          return Swal.fire({
            position: "center",
            icon: "info",
            title: "Debe agregar una moneda antes de configurar las fichas  ",
            showConfirmButton: false,
            timer: 1500,
          });
        }

        this.rouletteCurrency = rouletteCurrencies;

        if (data.chips.length) {
          this.isAddingChip = false;
          this.chips = data.chips.map((chip) => {
            return { ...chip, currency: chip.rouletteCurrency[0] };
          });

          return (this.$refs.configChips.modalChips = true);
        }

        if (!data.chips.length && !data.ok) {
          this.isAddingChip = true;
        }

        const { data: defaultChips } = await this.$http.get("/chips");

        if (defaultChips.ok) {
          this.chips = defaultChips.chips;
        }

        return (this.$refs.configChips.modalChips = true);
      } catch (error) {
        console.log("Error obteniendo chips roulettes", error);
      }
    },
    async configLimits(value) {
      if (this.$refs.configLimitRoulette.configLimits) {
        this.updatingLimits = false;
        this.rouletteCurrencies = [];
        this.noLimitsCurrencies = [];
        this.currWithOutLimits = [];
        this.limitsByCurrencies = [];
      }

      try {
        const id = value._id;
        this.rouletteId = value._id;

        const { data: currenciesLimits } = await this.$http.get(
          `roulettes/${value._id}/currency-limits`
        );

        if (currenciesLimits.ok) {
          this.updatingLimits = true;
        }

        const { data } = await this.$http.get(`roulettes/${id}/currencies`);

        if (data.ok) {
          this.rouletteCurrencies = data.currencies;

          if (currenciesLimits.ok) {
            const formattedCurrencies = [];
            const shorts = [];
            const currWithOutLimits = [];

            this.rouletteCurrencies.map((curr) => {
              currenciesLimits.limits.filter((limit) => {
                if (curr.short === limit.short) {
                  shorts.push(curr.short);
                  formattedCurrencies.push({
                    ...curr,
                    ...limit,
                    _id: curr._id,
                    limitId: limit._id,
                  });
                }
              });
            });

            this.rouletteCurrencies.filter((limit) => {
              if (!shorts.includes(limit.short)) {
                currWithOutLimits.push(limit);
              }
            });

            this.rouletteCurrencies = formattedCurrencies;

            this.currWithOutLimits = currWithOutLimits;
          }

          this.noLimitsCurrencies = this.currWithOutLimits.map(({ short }) => {
            return {
              currency: short,
            };
          });
          this.limitsByCurrencies = this.rouletteCurrencies.map(({ short }) => {
            return {
              currency: short,
            };
          });
        }

        return (this.$refs.configLimitRoulette.configLimits = true);
      } catch (error) {
        console.log("Error obteniendo monedas de la ruleta", error);
      }
    },
    async goTablero(roulette) {
      this.$router.push({
        name: "tablero",
        params: {
          id: roulette._id,
          provider: roulette.providerId,
          doubleZero: roulette.doubleZero,
        },
      });
    },
    async goManualResult(roulette) {
      console.log("AGREGAR RESULTADO MANUAL");

      const rouletteToShow = {
        id: roulette._id,
        provider: roulette.providerId,
        doubleZero: roulette.doubleZero,
      };

      localStorage.setItem("manual-roulette", JSON.stringify(rouletteToShow));

      this.$router.push({
        name: "ManualResult",
        params: rouletteToShow,
      });
    },
    async goJackpotBank(roulette) {
      this.roulette = roulette;
      this.$refs.jackpotBankComponent.bank = roulette.bank;
      this.$refs.jackpotBankComponent.modal = true;
    },
    async goProfitRoulette(roulette) {
      this.roulette = roulette;
      await this.reloadProfit(roulette);
      this.$refs.jackpotsModulesComponent.modal = true;
    },
    async reloadProfit(roulette) {
      this.$refs.jackpotsModulesComponent.isFetching = true;
      const { _id } = roulette;

      await this.fetchRoulettesJackpotsModule(_id);

      this.$refs.jackpotsModulesComponent.isFetching = false;
    },
    async setOpeningClosing(roulette) {
      this.roulette = roulette;
      this.$refs.openingClosingComponent.openingTime = roulette.openingTime;
      this.$refs.openingClosingComponent.closingTime = roulette.closingTime;
      this.$refs.openingClosingComponent.alwaysOpen = roulette.alwaysOpen;
      this.$refs.openingClosingComponent.modal = true;
    },
    async reloadJackpot(game) {
      this.setFetching(true);

      try {
        const { data } = await this.$http.get(`roulettes/${game._id}`);

        if (!data.ok) return (this.$refs.jackpotBankComponent.bank = 0);

        const { roulette } = data;

        if (!roulette.length) return (this.$refs.jackpotBankComponent.bank = 0);

        const { bank } = roulette[0];

        this.$refs.jackpotBankComponent.bank = bank;
        this.setFetching(false);
      } catch (error) {
        console.log("ERROR UPDATE ROULETTE", error);
        this.setFetching(false);
      }
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getData() {
      try {
        const options = { path: "roulettes", options: {}, type: "roulettes" };
        await this.fetchGames(options);
        this.tableData = this.mapGames;
        this.roulettes = this.mapGames;
      } catch (error) {
        console.log(error);
      }
    },
    closeModa() {
      this.$refs.pageForm.modal = false;
      this.typeform = {
        doubleZero: "",
        code: "",
        logo: "",
        roundDuration: "",
        lastJackpot: "",
        jackpotRounds: "",
        currenJackpotRound: "",
        jackpotWin: "",
        interface: "",
        minBet: null,
        maxBet: null,
      };
      this.rouletteId = "";
      this.chips = [];

      this.getData();
    },
    edit(item) {
      this.$http
        .get(`/roulettes/${item._id}/currencies`)
        .then(({ data }) => {
          this.typeform = { ...item, currencies: data.currencies };
        })
        .catch((err) => {
          console.log(err);
        });
      this.$refs.pageForm.modal = true;
    },
    remove(id) {
      Swal.fire({
        position: "center",
        icon: "question",
        title: "¿Estás seguro de eliminar esta ruleta?",
        showConfirmButton: true,
        confirmButtonText: "Si",
        showDenyButton: true,
      }).then((res) => {
        if (res.isConfirmed) {
          this.$http
            .delete(`roulettes/${id}`)
            .then(({ data }) => {
              if (!data.ok) {
                return Swal.fire({
                  position: "center",
                  icon: "error",
                  title: data.msg,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Ruleta eliminada con exito",
                showConfirmButton: false,
                timer: 1500,
              });
              this.getData();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    changeActivate(game) {
      const { _id: id, manualDisable } = game;

      const action = manualDisable ? "ACTIVAR" : "DESACTIVAR";

      const title = `DESEA ${action} LA RULETA?`;

      const text = manualDisable
        ? "LA RULETA ESTARA VISIBLE EN EL LOBBY, Y COMENZARA A RECIBIR RONDAS"
        : "LA RULETA NO SE VERA EN EL LOBBY, Y AL RECIBIR RONDAS NO SE ACTIVARA";

      Swal.fire({
        position: "center",
        icon: "question",
        title,
        showConfirmButton: true,
        confirmButtonText: "Si",
        showDenyButton: true,
        text,
      }).then((res) => {
        if (res.isConfirmed) {
          this.$http
            .put(`roulettes/${id}/changeActivate`)
            .then(({ data }) => {
              if (!data.ok) {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: data.msg,
                  showConfirmButton: true,
                });
              } else {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Operacion realizada con exito",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.getData();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    getCurrencies() {
      this.$http
        .get("/currencies")
        .then(({ data }) => {
          if (!data.ok) return console.log("No hay monedas");

          if (!data.currencies) {
            return console.log("No hay currencies", data.currencies);
          }
          const availableCurrencies = data.currencies.filter(
            (currency) => currency.status === true
          );

          this.currencies = availableCurrencies;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRouletteFisics() {
      this.$http
        .get("roulettes/fisics/get")
        .then(({ data }) => {
          if (!data.ok) return console.log("No hay Ruletas Fisicas");

          if (!data.rouletteFisics) {
            return console.log("No hay rouletteFisics", data.rouletteFisics);
          }
          this.rouletteFisics = data.rouletteFisics;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async addCurrency(item) {
      await this.fetchGameCurrencies({ path: "roulettes", id: item._id });

      const gameCurrencies = this.getGameCurrencies;

      this.roulette = { ...item, currencies: gameCurrencies };

      this.currencies = this.getCurrencies();

      this.$refs.addCurrency.modal = true;
    },
    importCurrency(item) {
      this.$http
        .get("/roulettes")
        .then(({ data }) => {
          const roulettes = data.roulettes;
          const roulettesToImport = roulettes.filter(
            (roulette) => roulette._id !== item._id
          );
          this.roulettes = roulettesToImport;
        })
        .catch((err) => {
          console.log(err);
        });

      this.roulette = item;
      this.$refs.ImportCurrencies.modal = true;
    },
    handleGoBack() {
      this.configChips();
    },
    async addAnotherChip() {
      try {
        const { data: defaultChips } = await this.$http.get("/chips");

        if (defaultChips.ok) {
          this.chips = defaultChips.chips;
          this.isAddingChip = true;
        }

        return (this.$refs.configChips.modalChips = true);
      } catch (error) {
        console.log("Error obteniendo default chips", error);
      }
    },
    handleError(e) {
      e.target.src = "https://via.placeholder.com/150";
    },

    handleOpen() {
      this.$refs.gameMenu.modal = true;
      const items = filterMenu(this.$route.meta.parent)[0].subItems;

      this.gameMenuItems = items;
    },
    handleDirection(to) {
      if (this.$route.path === to.link) {
        return (this.$refs.gameMenu.modal = false);
      }

      return this.$router.push(to.link);
    },
    configAnimals(item) {
      this.roulette = item;
      const { animals } = item;

      let formattedAnimals = {};
      for (let key in animals) {
        const animal = animals[key];
        formattedAnimals[animal.number] = animal;
      }

      const formatted = formatAnimals(formattedAnimals);

      this.formattedAnimals = formatted;
      this.$refs.configAnimals.modal = true;
    },
    ...mapMutations({
      setFetching: "games/setFetching",
    }),
    ...mapActions({
      fetchGameCurrencies: "games/fetchGameCurrencies",
      fetchGames: "games/findGames",
      update: "games/updateRoulette",
      fetchRoulettesJackpotsModule: "games/fetchRoulettesJackpotsModule",
    }),
  },
  middleware: "authentication",
};
</script>

<style scoped>
.action-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100px;
}

.dropdown-menu-left {
  left: -3rem !important;
  background: red !important;
  color: brown !important;
}
</style>
