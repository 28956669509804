export const formatAnimals = (formattedAnimals) => {
  const formatted = []
  for (let i = 0; i < 38; i++) {
        if (formattedAnimals[i]) {
          if (formattedAnimals[i].number === 37) {
            formatted.push({
              ...formattedAnimals[i],
              number: 37
            });
            continue
          }

          formatted.push(formattedAnimals[i]);
          continue
        }

        const defaultAnimal = {
          number: i,
          image: ""
        }
        formatted.push(defaultAnimal);
      }

      return formatted
}