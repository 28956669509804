<template>
  <b-modal v-model="modal" size="lg" hide-footer>
    <template #modal-title> JACKPOTS MODULES </template>
    <div>
      <h5>Ruleta: {{ game.name }}</h5>
      <div class="d-flex justify-content-center">
        <h2>PROFIT: {{ profit }}</h2>
      </div>
      <template v-if="jackpotsModules.length">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                {{ $t("filter.show") }}&nbsp;
                <vs-select v-model="perPage" class="shadow-lg">
                  <vs-option
                    v-for="(op, index) in pageOptions"
                    :key="index"
                    :label="op"
                    :value="op"
                    >{{ op }}</vs-option
                  >
                </vs-select>
                {{ $t("filter.entries") }}
              </label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-end">
              <label class="d-inline-flex align-items-center">
                {{ $t("filter.search") }}:
                <vs-input
                  primary
                  v-model="filter"
                  type="search"
                  placeholder="Buscar..."
                  class="shadow-lg" />
              </label>
            </div>
          </div>
        </div>
        <b-table
          striped
          hover
          :items="jackpotsModules"
          :fields="fields"
          :filter="filter"
          :filter-included-fields="filterOn"
          :per-page="perPage"
          :current-page="currentPage">
          <template #cell(id)="data">
            <div class="d-flex justify-content-center">
              <vs-tooltip right>
                <vs-button @hover="getRoulettesJackpotsModule(data.item.id)">
                  {{ data.item.id }}
                </vs-button>
                <template
                  #tooltip
                  v-if="getRoulettesJackpotsModule(data.item.id)">
                  <span
                    v-for="(item, i) of getRoulettesJackpotsModule(data.item.id)
                      .jackpost"
                    :key="i"
                    class="jackpot-item">
                    X{{ item }}
                  </span>
                </template>
              </vs-tooltip>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="jackpotsModules.length"
          :per-page="perPage"
          aria-controls="my-table"></b-pagination>
        <vs-button
          :loading="isFetching"
          class="mx-auto"
          @click="$emit('reload', game)"
          >ACTUALIZAR</vs-button
        >
      </template>
      <p v-else>RULETA SIN JACKPOTS MODULES</p>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    game: {
      type: Object,
      default: () => ({}),
    },
    jackpotsModules: {
      type: Array,
      default: () => {
        return [
          {
            id: 1,
          },
        ];
      },
    },
    profit: {
      type: Number,
      default: () => 0,
    },
  },
  computed: {
    ...mapGetters({
      getRoulettesJackpotsModule: "games/getRoulettesJackpotsModule",
    }),
  },
  data() {
    return {
      modal: false,
      isFetching: false,
      fields: [
        {
          key: "id",
          label: "ID",
          class: "text-center",
        },
      ],
      filter: null,
      filterOn: ["id"],
      perPage: 10,
      currentPage: 1,
      pageOptions: [10, 50, 100, 200],
    };
  },
};
</script>

<style lang="scss" scoped>
.jackpot-item {
  background-color: white;
  margin-right: 1rem;
  color: black;
  padding: 0.2rem;
  border-radius: 4px;
}
</style>
